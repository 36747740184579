import React from "react"
import { useCustomerContext } from "@app/providers/customer"
import type { Props } from "@app/pages/account/orders"
import type { PageProps } from "@root/types/global"
import CustomerOrdersCard from "./CustomerOrdersCard"
import CustomerLayout from "../Layout/CustomerLayout"

const CustomerOrders: React.FC<PageProps<Props>> = ({ page }) => {
  const { customer } = useCustomerContext()
  return <CustomerLayout title={page?.title}>{customer?.id && <CustomerOrdersCard />}</CustomerLayout>
}

export default CustomerOrders
